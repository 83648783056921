/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify';
import pinia from '~/stores';
import router from '~/router';
import Vue3Toastify from 'vue3-toastify';
import mitt from 'mitt';

const emitter = mitt<BusEvents>();

// Types
import type { App } from 'vue';
import { i18n } from './i18n';
import { BusEvents } from '~/events';
import { EventBus } from '~/symbols';

export function registerPlugins(app: App) {
  app
    .provide(EventBus, emitter)
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(Vue3Toastify, {
      position: 'bottom-left',
      theme: 'light',
      transition: 'bounce',
      // autoClose: false,
      autoClose: 5000, // Automatically close after 3 seconds
      pauseOnHover: true,
      closeOnClick: true,
    })
    .use(i18n);
}
