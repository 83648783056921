import { useAuth } from '~/composables/auth';
/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { Routes } from './routes';
import { UserRole } from '~/types/api/user-role';
import { useAppStore } from '~/stores/app';
import { useTerminal } from '~/composables/terminal';
import { TerminalStatus } from '~/types/api/terminal-status';

const routes: RouteRecordRaw[] = [
  { path: '/', name: Routes.Index, redirect: { name: Routes.AdminDashboard } },
  //#region Admin Panel
  {
    path: '/admin',

    children: [
      {
        path: 'auth',
        component: () => import('~/layouts/blank.vue'),
        children: [
          {
            path: 'signin',
            name: Routes.AuthSignin,
            component: () => import('~/views/admin/auth/signin.vue'),
            meta: {
              redirectIfAuthenticated: true,
            },
          },
        ],
      },
      {
        path: 'permission-denied',
        name: Routes.AdminPermissionDenied,
        component: () => import('~/views/admin/permission-denied.vue'),
      },
      {
        path: '',
        component: () => import('~/layouts/admin.vue'),
        meta: {
          requiresAuth: true,
          permittedRoles: [UserRole.Admin, UserRole.Client],
        },
        children: [
          {
            path: '',
            name: Routes.AdminDashboard,
            // component: () => import('~/views/admin/index.vue'),
            redirect: { name: Routes.AdminUsers },
          },
          {
            path: 'terminals',
            name: Routes.AdminTerminals,
            component: () => import('~/views/admin/terminals/index.vue'),
            meta: { title: 'Terminals' },
          },
          {
            path: 'clients',
            name: Routes.AdminClients,
            component: () => import('~/views/admin/clients/index.vue'),
            meta: { title: 'Mandanten' },
          },
          {
            path: 'forms',
            meta: { title: 'Fragebögen' },
            children: [
              {
                path: '',
                name: Routes.AdminForms,
                component: () => import('~/views/admin/forms/index.vue'),
              },
              {
                path: ':formid',
                name: Routes.AdminForm,
                component: () => import('~/views/admin/forms/[id]/index.vue'),
              },
            ],
          },
          {
            path: 'translations',
            name: Routes.AdminTranslations,
            component: () => import('~/views/admin/translations/index.vue'),
            meta: { title: 'Übersetzungen' },
          },
          {
            path: 'users',
            meta: { title: 'Benutzer' },
            children: [
              {
                path: '',
                name: Routes.AdminUsers,
                component: () => import('~/views/admin/users/index.vue'),
              },
              {
                path: ':userid',
                name: Routes.AdminUser,
                component: () => import('~/views/admin/users/[id]/index.vue'),
              },
            ],
          },
          {
            path: 'papers',
            meta: { title: 'Dokumente' },
            children: [
              {
                path: '',
                name: Routes.AdminPapers,
                component: () => import('~/views/admin/papers/index.vue'),
              },
              {
                path: ':paperid',
                name: Routes.AdminPaper,
                component: () => import('~/views/admin/papers/[id]/index.vue'),
              },
            ],
          },
          {
            path: '/(.*)*',
            redirect: {
              name: Routes.AdminDashboard,
              replace: true,
              force: true,
            },
          },
        ],
      },
    ],
  },
  //#endregion
  //#region Terminal
  {
    path: '/terminal',
    component: () => import('~/layouts/terminal.vue'),
    children: [
      {
        path: 'register',
        children: [
          {
            path: '',
            name: Routes.TerminalRegister,
            component: () => import('~/views/terminal/register/index.vue'),
            meta: {
              redirectIfRegistered: true,
            },
          },
          {
            path: 'not-approved',
            name: Routes.TerminalRegisterNotApproved,
            component: () =>
              import('~/views/terminal/register/not-approved.vue'),
            meta: {
              requiresTerminalRegistration: true,
            },
          },
        ],
      },
      {
        path: 'auth',
        children: [
          {
            path: 'signup',
            name: Routes.TerminalSignup,
            component: () => import('~/views/terminal/auth/signup.vue'),
            meta: {
              requiresTerminalRegistration: true,
              redirectIfAuthenticated: true,
            },
          },
          {
            path: 'signin',
            name: Routes.TerminalSignin,
            component: () => import('~/views/terminal/auth/signin.vue'),
            meta: {
              requiresTerminalRegistration: true,
              redirectIfAuthenticated: true,
            },
          },
        ],
      },
      {
        path: 'permission-denied',
        name: Routes.TerminalPermissionDenied,
        component: () => import('~/views/terminal/permission-denied.vue'),
      },
      {
        path: '',
        meta: {
          requiresTerminalRegistration: true,
          requiresTerminalApproval: true,
          // requiresAuth: true,
        },
        children: [
          {
            path: 'intro',
            name: Routes.TerminalIntro,
            component: () => import('~/views/terminal/intro.vue'),
            meta: { redirectIfAuthenticated: true },
          },
          {
            path: 'select-language',
            name: Routes.TerminalSelectLanguage,
            component: () => import('~/views/terminal/select-language.vue'),
            meta: { redirectIfAuthenticated: true },
          },
          {
            path: 'scan-passport',
            name: Routes.TerminalScanPassport,
            component: () => import('~/views/terminal/scan-passport.vue'),
            meta: { redirectIfAuthenticated: true },
          },
          {
            path: 'check-identity',
            name: Routes.TerminalCheckIdentity,
            component: () => import('~/views/terminal/check-id.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'dashboard',
            children: [
              {
                path: '',
                name: Routes.TerminalDashboard,
                component: () => import('~/views/terminal/dashboard/index.vue'),
                meta: { requiresAuth: true },
              },
            ],
          },
          {
            path: 'last-employments',
            meta: { requiresAuth: true },
            name: Routes.TerminalLastEmployments,
            component: () =>
              import('~/views/terminal/last-employments/index.vue'),
          },
          {
            path: 'forms/',
            meta: { requiresAuth: true },
            children: [
              {
                path: ':formid',

                children: [
                  {
                    path: '',
                    name: Routes.TerminalForm,
                    component: () => import('~/views/terminal/forms/[id].vue'),
                  },
                  {
                    path: 'steps/',
                    children: [
                      {
                        path: ':stepid',
                        name: Routes.TerminalFormStep,
                        component: () =>
                          import('~/views/terminal/forms/step.vue'),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        redirect: {
          name: Routes.TerminalIntro,
          replace: true,
          force: true,
        },
      },
    ],
  },
  //#endregion
  // catch all
  // {
  //   path: '/(.*)*',
  //   redirect: { name: Routes.AdminDashboard, replace: true, force: true },
  // },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  const {
    title,
    requiresTerminalRegistration,
    requiresTerminalApproval,
    redirectIfRegistered,
    requiresAuth,
    redirectIfAuthenticated,
    permittedRoles,
  } = to.meta;

  if (title) {
    useAppStore().title = title;
  } else {
    useAppStore().title = '';
  }

  const isAdminPage = to.fullPath.startsWith('/admin');

  //#region terminal
  if (requiresTerminalRegistration || requiresTerminalApproval) {
    const isTerminalRegistered = await useTerminal().isRegistered();
    const terminal = await useTerminal().getCurrentTerminal();
    console.log({ isTerminalRegistered, status: terminal?.status });

    // check if terminal is registered
    if (requiresTerminalRegistration && !isTerminalRegistered) {
      return next({ name: Routes.TerminalRegister });
    }

    // check if terminal is approved
    if (
      requiresTerminalApproval &&
      terminal?.status !== TerminalStatus.Approved
    ) {
      console.log({ requiresTerminalRegistration, requiresTerminalApproval });
      return next({ name: Routes.TerminalRegisterNotApproved });
    }
  }
  if (redirectIfRegistered) {
    const isTerminalRegistered = await useTerminal().isRegistered();

    // check if terminal is already registered
    if (redirectIfRegistered && isTerminalRegistered) {
      return next({ name: Routes.TerminalIntro });
    }
  }
  //#endregion

  //#region admin
  if (requiresAuth || redirectIfAuthenticated || permittedRoles) {
    const authenticated = await useAuth().isAuthenticated();
    const user = await useAuth().getCurrentUser();

    // redirect to signin page if the user is not authenticated
    if (requiresAuth && !authenticated) {
      if (isAdminPage) {
        return next({
          name: Routes.AuthSignin,
          query: { redirect: to.fullPath },
        });
      } else {
        return next({ name: Routes.TerminalIntro });
      }
    }

    // redirect if authenticated
    if (redirectIfAuthenticated && authenticated) {
      if (isAdminPage) {
        return next({ name: Routes.AdminDashboard });
      } else {
        return next({ name: Routes.TerminalDashboard });
      }
    }

    // redirect to permission-denied page if the user has not the sufficient permissions
    if (permittedRoles && user) {
      if (!permittedRoles.includes(user.role)) {
        if (isAdminPage) {
          return next({
            name: Routes.AdminPermissionDenied,
            query: { redirect: to.fullPath },
          });
        } else {
          return next({
            name: Routes.TerminalPermissionDenied,
            query: { redirect: to.fullPath },
          });
        }
      }
    }
  }
  //#endregion

  return next();
});

export default router;
