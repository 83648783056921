import { useTerminalStore } from '~/stores/terminal';

export const useTerminal = () => {
  const store = useTerminalStore();

  const isRegistered = async () => {
    store.getToken();
    return await store.getIsRegistered();
  };
  const getToken = () => {
    return store.getToken();
  };

  const getCurrentTerminal = async () => {
    store.getToken();
    return await store.getCurrentTerminal();
  };

  return {
    isRegistered,
    getToken,
    getCurrentTerminal,
  };
};
