import axios from 'axios';
import { useAuth } from './auth';
import { useTerminal } from './terminal';

type UseApiOptions = {
  locale?: string;
};

export const useApi = (options?: UseApiOptions) => {
  const api = axios.create({
    baseURL: `${import.meta.env.API_BASE_URL}/api`,
  });

  api.interceptors.request.use((config) => {
    // language
    config.headers.set('x-locale', options?.locale ?? 'de');

    // admin authentication
    const authToken = useAuth().getToken();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    // terminal authentication
    const terminalToken = useTerminal().getToken();
    if (terminalToken) {
      config.headers.set('x-terminal-token', terminalToken);
    }
    return config;
  });

  return api;
};
