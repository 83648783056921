import { useAuthStore } from '~/stores/auth';
import { SigninDto } from '~/types/api/auth/signin.dto';
import { SignupDto } from '../types/api/auth/signup.dto';

export const useAuth = () => {
  const store = useAuthStore();

  const signup = async (data: SignupDto, locale?: string) => {
    return store.signup(data, locale);
  };

  const signin = async (data: SigninDto, locale?: string) => {
    return store.signin(data, locale);
  };

  const signout = () => {
    return store.signout();
  };

  const isAuthenticated = async () => {
    store.getToken();
    return await store.getIsAuthenticated();
  };

  const getToken = () => {
    return store.getToken();
  };

  const getCurrentUser = async () => {
    store.getToken();
    return await store.getCurrentUser();
  };

  return {
    signup,
    signin,
    signout,
    getCurrentUser,
    isAuthenticated,
    getToken,
  };
};
