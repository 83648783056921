import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useApi } from '~/composables/api';
import { TerminalDto } from '~/types/api/terminal/terminal.dto';

const TOKEN_KEY = 'terminal-token';

export const useTerminalStore = defineStore('terminal', () => {
  const isRegistered = ref<boolean>(false);
  const token = ref<string | null>(null);
  const terminal = ref<TerminalDto | null>(null);

  const register = async (
    token: string,
    name: string,
    location?: string,
    printerSn?: string,
  ) => {
    const res = await useApi().post(`/terminals`, {
      token,
      name,
      location,
      printerSn,
    });
    const terminal = res.data as TerminalDto;

    setToken(terminal.token);
  };

  const getCurrentTerminal = async (): Promise<TerminalDto | null> => {
    try {
      const res = await useApi().get<TerminalDto>(`/terminals/me`);
      setToken(token.value);
      terminal.value = res.data;
      return res.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const getIsRegistered = async () => {
    const token = getToken();
    return token !== null;
  };

  const getToken = () => {
    token.value = localStorage.getItem(TOKEN_KEY);
    return token.value;
  };
  const setToken = (value: string | null) => {
    token.value = value;
    if (value === null) {
      localStorage.removeItem(TOKEN_KEY);
    } else {
      localStorage.setItem(TOKEN_KEY, value);
    }
  };

  return {
    register,
    getToken,
    getIsRegistered,
    getCurrentTerminal,
    isRegistered,
  };
});
