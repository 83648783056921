import * as VueI18n from 'vue-i18n';

import de from '~/locales/de.json';
import en from '~/locales/en.json';
import fr from '~/locales/fr.json';
import uk from '~/locales/uk.json';
import ar from '~/locales/ar.json';

export const i18n = VueI18n.createI18n({
  locale: 'en',
  fallbackLocale: 'de',
  availableLocales: ['de', 'en', 'fr', 'uk', 'ar'],
  globalInjection: true,
  legacy: false,

  messages: {
    de: de,
    en: en,
    fr: fr,
    uk: uk,
    ar: ar,
  },
});
